import React, { useCallback, useEffect, useState } from 'react'
import firebase from 'gatsby-plugin-firebase';
import { Accordion, Icon, Input } from 'semantic-ui-react';

import Layout from '../../components/layout'
import Header from '../../components/Header'
import { useUserState } from '../../context/UserContext'
import { Link } from 'gatsby'

const DataRow = ({ label, value }) => (
  <div>
    <span className="label">{label}</span>
    <span className="value">{value}</span>
  </div>
);

const Registrations = () => {
  const { user } = useUserState();
  const [registrations, setRegistrations] = useState([]);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);
  const [filterValue, setFilterValue] = useState('');

  const filteredRegistrations = filterValue
    ? registrations.filter((registration) =>
      registration.firstNames.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
      registration.surname.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
      (registration.policyNumber && registration.policyNumber.toLowerCase().includes(filterValue.trim().toLowerCase())))
    : registrations;

  useEffect(() => {
    firebase.firestore().collection('registrations').get().then((querySnapshot) => {
      setRegistrations(querySnapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  const onFilter = useCallback(({ target: { value } }) => {
    setFilterValue(value);
  }, []);

  const onAccordionClick = useCallback((_e, titleProps) => {
    const { index } = titleProps;

    setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index);
  }, [activeAccordionIndex]);

  if (!user) {
    return (
      <Layout title="Registrations">
        <Header title="Registrations" subtitle="Unauthorized access" />
        <div id="main">
          <section id="content" className="main">
            <p>You are not authorized to view this page, please <Link to="/sign-in">Sign In</Link> to continue.</p>
          </section>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Registrations">
      <Header title="Registrations" subtitle="Members that have registered" />
      <div id="main">
        <section id="content" className="main">
          <Input
            fluid
            icon="search"
            placeholder="Search for a name / surname / policy number"
            onChange={onFilter}
            value={filterValue}
          />
          <Accordion
            className="registrations"
            fluid
            styled
          >
            {filteredRegistrations.map((registration, index) => (
              <div key={index}>
                <Accordion.Title
                  active={activeAccordionIndex === index}
                  index={index}
                  onClick={onAccordionClick}
                >
                  <Icon name='dropdown' />
                  {`${registration.surname} ${registration.firstNames}`}
                </Accordion.Title>
                <Accordion.Content active={activeAccordionIndex === index}>
                  <DataRow
                    label="Policy No."
                    value={registration.policyNumber}
                  />
                  <DataRow
                    label="Surname"
                    value={registration.surname}
                  />
                  <DataRow
                    label="First Names"
                    value={registration.firstNames}
                  />
                  <DataRow
                    label="Date of Birth"
                    value={registration.dateOfBirth}
                  />
                  <DataRow
                    label="Include burial cover"
                    value={registration.funeralCover ? 'Yes' : 'No'}
                  />
                  <hr />
                  <DataRow
                    label="Physical Address"
                    value={registration.physicalAddress}
                  />
                  <DataRow
                    label="Cell Number"
                    value={registration.cellNumber}
                  />
                  <DataRow
                    label="Work Number"
                    value={registration.workNumber}
                  />
                  <DataRow
                    label="Email Address"
                    value={registration.emailAddress}
                  />
                  <DataRow
                    label="Referral Code"
                    value={registration.referralCode}
                  />
                  <hr />
                  {registration.dependants && registration.dependants.map((dependant, index) => (
                    <div className="dependants" key={index}>
                      <span>Dependant no. {index + 1}</span>
                      <DataRow
                        label="Full Names"
                        value={dependant.fullNames}
                      />
                      <DataRow
                        label="Surname"
                        value={dependant.surname}
                      />
                      <DataRow
                        label="Relationship"
                        value={dependant.relationship}
                      />
                      <DataRow
                        label="Cover"
                        value={dependant.cover}
                      />
                    </div>
                  ))}
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        </section>
      </div>
    </Layout>
  );
}

export default Registrations;
